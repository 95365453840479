import { SelectionChangedEvent } from "ag-grid-community";
import { TariffNatureEntity } from "domain/entity/TariffNature/TariffNatureEntity";
import { INITIAL_TARIFF_NATURE_COL_DEF } from "presentation/constant/TariffNature/TariffNatureMaintenanceColumnDefinition";
import { TariffNatureMaintenanceConstant } from "presentation/constant/TariffNature/TariffNatureMaintenanceConstant";
import { useTariffNatureMaintenanceVM } from "presentation/hook/TariffNature/useTariffNatureMaintenanceVM";
import { useTariffNatureMaintenanceTracked } from "presentation/store/TariffNature/TariffNatureMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


const TariffNatureTablePanel:React.FC = () => {
    const [tariffNatureState] = useTariffNatureMaintenanceTracked();
    const tariffNatureVM = useTariffNatureMaintenanceVM();
    const gridRef: any = useRef(null);
    const {tableData} = tariffNatureState;

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {     
        const selectedRows = e.api.getSelectedRows();

        tariffNatureVM.updateSelectedRows(tableData,selectedRows);
    }, [tariffNatureVM, tableData])

    const handleAddClick = useCallback(() => {
        tariffNatureVM.onAdd();
    }, [tariffNatureVM])

    const handleRowDoubleClick = useCallback((entity: TariffNatureEntity) => {
          tariffNatureVM.onEdit(entity);
    }, [tariffNatureVM])

    const memoTable = useMemo(() => {
        return (
        <HPHTable
            id='tariff-nature-table'
            headerLabel={TariffNatureMaintenanceConstant.TITLE}
            isNewColumnSetting={true}
            columns={INITIAL_TARIFF_NATURE_COL_DEF}
            data={tableData??[]}
            showPaginator={false}
            editable={false}
            showAddIcon={true}
            onAddClick={handleAddClick}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            isRowHighligted={true}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any, entity: TariffNatureEntity) => handleRowDoubleClick(entity)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
            />
        );
    },[tableData, handleAddClick, handleSelectionChange, handleRowDoubleClick])

    return <><TableWrapper>
            {memoTable}
        </TableWrapper>
        </>;
}

export default memo(TariffNatureTablePanel);
